.layout-app-footer {
  @include transition(margin-left $transitionDuration);
  background-color: $footerBgColor;
  color: white;
  width: 100%;
  margin-bottom: -0.5em;
  .header-home {
    text-align: center;
    width: auto;
    height: 100px;
  }
  .header-twitter {
    height: 40px;
  }

  .text {
    font-size: 1em;
  }

  .developed-by-text {
    font-size: 1em;
    color: white;
  }

  .text-center {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button-margin {
    margin: 0px 5px;
  }

  .link {
    margin-left: 4px;
    margin-bottom: 2px;
  }

  .link:hover {
    text-decoration: none;
  }

  .text-link {
    font-size: 0.8em;
    color: white;
    &:hover {
      color: $topbarItemHoverColor;
    }
  }
}
