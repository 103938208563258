.layout-profile {
  .user-info {
    img {
      border-radius: 50%;
      width: 50%;
      height: 50%;
    }
  }
  @media screen and (min-width: 801px) {
    .user-info-container {
      width: 40%;
      padding: 0.5%;
    }
    .activity-list-container {
      width: 60%;
      padding: 0.5%;
    }
  }
  @media only screen and (max-width: 800px) {
    .user-info-container {
      width: 100%;
      padding: 0.5%;
    }
    .activity-list-container {
      width: 100%;
    }
  }
}
