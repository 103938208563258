.layout-statistics {
  .header-event {
    text-align: left;
    background-color: #f7f4eb;
  }

  .left-text {
    text-align: start;
    margin-left: 5px;
  }

  .align-left {
    text-align: left;
  }
}
