.layout-panel {
  margin: 0px;

  div {
    background-color: #f5f5f5;
    position: relative;
    text-align: center;
  }
  .vertical-line {
    height: 30px;
    border-right: 1px solid grey;
    display: inline-block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }
}
.toolbar-button {
  margin-right: 0.25em !important;
}

.datatable-responsive .p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 800px) {
  tr.p-datatable-row {
    border: 1px solid #c8c8c8;
  }
  .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-thead > tr > th,
  .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tfoot > tr > td {
    display: none !important;
  }

  .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td {
    text-align: left;
    display: block;
    width: 100%;
    float: left;
    clear: left;
    border: 0 none;
  }

  .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td .p-column-title {
    padding: 0.4rem;
    min-width: 30%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4rem;
    font-weight: bold;
  }

  .datatable-responsive .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:last-child {
    border-bottom: 1px solid var(--surface-d);
  }
}
