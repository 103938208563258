.layout-app-header {
  .header-home {
    width: auto;
    height: 50px;
  }

  .header-logo {
    width: auto;
    height: 77px;
  }

  .header-top {
    width: 85%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-text {
    color: $CURIOUS_BLUE;
    font-size: 3rem;
    font-weight: bolder;
    font-stretch: wider;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .buttonArea {
    width: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
