.layout-article {
  .margin-bottom-15 {
    margin-bottom: 15px;
  }

  .display-none {
    display: none;
  }

  div.p-panel-titlebar,
  div.p-toolbar.p-component {
    background-color: white;
  }

  .p-sortable-column,
  tr.p-datatable-row,
  th {
    background-color: white !important;
    color: black !important;
  }
  span.p-sortable-column-icon.pi.pi-fw.pi-sort-up {
    color: #333333;
  }
  span.p-sortable-column-icon.pi.pi-fw.pi-sort-down {
    color: #333333;
  }
  tr.p-datatable-row.p-highlight {
    background-color: #3e97d3 !important;
    color: white !important;
  }

  .p-toolbar-group-left {
    button.p-button.p-component {
      background-color: #3e97d3;
      border-radius: 10px;
      border: none;
    }
  }

  .p-toolbar-group-right {
    button.p-button.p-component {
      background-color: #ef4723;
      border-radius: 10px;
      border: none;
    }
  }

  .dialog-width {
    width: 1000px;
  }

  .text-left {
    text-align: left;
  }

  .vertic-line {
    margin-right: 1em;
    border: 0.01px solid grey;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  .column-width-150 {
    width: 150px;
  }

  .column-width-200 {
    width: 200px;
  }

  .column-width-250 {
    width: 250px;
  }

  .column-width-230 {
    width: 230px;
  }

  .font-size-small {
    font-size: 0.8em;
  }

  .font-size-medium {
    font-size: medium;
  }

  .color-red {
    color: red;
  }

  .text-center {
    text-align: center;
  }

  .text-bold {
    font-weight: bold;
  }

  .card {
    width: 22rem;
    margin: 0 auto;

    .card-title {
      color: #3c90a3;
    }
  }

  a:hover {
    text-decoration: none;
  }

  .content-preview {
    width: 100%;

    .content-title {
      color: #3c90a3;
    }
  }

  .scrollable-content {
    box-sizing: border-box;
    height: 100%;
    outline: none;
    overflow-y: auto;
    padding: 12px 15px;
    tab-size: 4;
    -moz-tab-size: 4;
    text-align: left;
  }

  .ql-editor p {
    text-align: "justify";
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #848484;
  }

  .panel-article {
    .p-panel-titlebar {
      font-size: large;
      background-color: $blue;
    }

    .p-toolbar-separator {
      margin-right: 0.25em;
      margin-left: 0.25em;
    }
  }
}

.horizontal-line {
  color: white;
}

.p-datatable {
  .p-datatable-wrapper {
    .p-datatable-thead {
      th {
        text-align: center;
      }
      tr {
        text-align: center;
      }
      .p-sortable-column {
        text-align: center;
      }
      .p-filter-column {
        .p-inputtext {
          width: 100%;
        }
      }
    }
  }
  .p-datatable-tbody {
    tr {
      text-align: center;
      td {
        text-align: center;
      }
      .column-left {
        text-align: left;
      }
      .column-right {
        text-align: right;
      }
    }
  }
}

.image-position-left {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
  float: left;
}

.image-position {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
}

.tab-view {
  .p-tabview {
    padding: 0px;
  }
}

.margin-top-bottom-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.p-toolbar-group-right {
  .p-button {
    margin-left: 10px;
  }
}

.padding-0 {
  padding: 0px;
}
