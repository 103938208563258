.layout-registration-status {
  text-align: center;

  .title-registration {
    padding: 1rem;
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    line-height: 2rem;
    letter-spacing: 0.5rem;
    color: #001764;
    text-transform: uppercase;
  }

  .icon {
    width: 15%;
  }

  .margin-top {
    margin-top: 20px;
  }

  .footer {
    position: absolute;
    bottom: 0;
  }
}
