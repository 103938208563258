.layout-progress {
  //   padding: 0px;
  text-align: center;

  img {
    width: 56px;
    margin: 10px;
  }

  .width-180 {
    width: 180px;
  }

  .level,
  .current-point {
    text-align: center;
    margin-top: 10px;
    font-size: 20px;
  }

  .current-point {
    margin-bottom: 20px;
  }

  .milestone {
    .p-card-content {
      height: 110px;
    }
  }

  .panel-statistics {
    .header-icons {
      background-color: #33adff;
      margin-left: 0px;
      margin-right: 0px;
    }
    .p-panel-titlebar {
      text-align: center;
      color: white;
      background: linear-gradient(90deg, #004885, #009ad8);
    }
    .p-panel-content {
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 6px;
    }
  }

  .panel-progress {
    .p-panel-titlebar {
      text-align: center;
      color: white;
      background: linear-gradient(90deg, #004885, #009ad8);
    }
  }
}
