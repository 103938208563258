.layout-comment {
  .edit-delete-comment {
    padding: 0px;
    color: orange;
  }

  .profile-picture {
    img {
      border-radius: 50%;
    }
  }

  .height-50px {
    height: 50px;
  }

  .edit-delete-comment:hover {
    color: red;
    cursor: pointer;
  }

  .post-comment {
    color: orange;
  }

  .post-comment:hover {
    color: blue;
    cursor: pointer;
  }

  .text-input {
    padding-left: 0px;
  }

  .margin-top-0 {
    margin-top: 0px;
  }

  .font-size-10 {
    font-size: 11px;
  }

  .padding-0 {
    padding: 0px;
  }
}
