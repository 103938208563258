.layout-event {
  .margin-bottom-15 {
    margin-bottom: 15px;
  }

  .dialog-width {
    width: 1000px;
  }

  .p-datepicker {
    position: absolute !important;
  }

  a:hover {
    text-decoration: none;
  }

  .scrollable-content {
    box-sizing: border-box;
    height: 100%;
    outline: none;
    overflow-y: auto;
    padding: 12px 15px;
    tab-size: 4;
    -moz-tab-size: 4;
    text-align: left;
  }

  .p-datatable {
    .p-datatable-wrapper {
      .p-datatable-thead {
        th {
          text-align: center;
        }
        tr {
          text-align: center;
        }
        .p-sortable-column {
          background-color: white;
          text-align: center;
        }
        .p-filter-column {
          background-color: white;
          .p-inputtext {
            width: 100%;
          }
        }
      }
    }
  }

  .p-toolbar-group-left {
    button.p-button.p-component {
      background-color: #3e97d3;
      border-radius: 10px;
      border: none;
    }
  }

  div.p-toolbar.p-component {
    background-color: white;
  }

  .p-toolbar-group-right {
    button.p-button.p-component {
      background-color: #ef4723;
      border-radius: 10px;
      border: none;
    }
  }

  .column-width-150 {
    width: 150px;
  }

  .column-width-170 {
    width: 170px;
  }

  .column-width-200 {
    width: 200px;
  }

  .column-width-250 {
    width: 250px;
  }

  .column-width-230 {
    width: 230px;
  }

  img {
    align-content: center;
    margin: 0;
    padding: 0;
  }

  .text-left {
    text-align: left;
  }

  .vertic-line {
    margin-right: 1em;
    border: 0.01px solid grey;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  .font-size-small {
    font-size: 0.8em;
  }

  .font-size-medium {
    font-size: medium;
  }

  .color-red {
    color: red;
  }

  .p-datatable .p-datatable-tbody tr .td-left {
    text-align: left;
  }

  .p-datatable .p-datatable-tbody tr .td-right {
    text-align: right;
  }

  .text-center {
    text-align: center;
  }

  .text-bold {
    font-weight: bold;
  }

  .panel-event {
    .p-panel-titlebar {
      font-size: large;
      background-color: $blue;
    }

    .p-toolbar-separator {
      margin-right: 0.25em;
      margin-left: 0.25em;
    }
  }

  .panel-future-events {
    .p-panel-titlebar {
      text-align: center;
      color: white;
      background: linear-gradient(90deg, #ffb979, #ff3e57);
    }
  }
  .panel-finished-events {
    .p-panel-titlebar {
      text-align: center;
      color: white;
      background: linear-gradient(90deg, #004885, #009ad8);
    }
  }

  .image-position {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
  }
}

.view-comments {
  color: orange;
  margin: 5px;
}

.view-comments:hover {
  color: blue;
  cursor: pointer;
}

.column-width-100 {
  width: 100%;
}

.p-datatable .p-datatable-thead > tr > th {
  background-color: white;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #333333;
}

.p-datatable .p-sortable-column.p-highlight {
  color: #333333;
}

.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
  color: #333333;
}

.p-datatable .p-sortable-column.p-highlight:hover {
  color: #333333;
}

.horizontal-line {
  color: white;
}
.gmap {
  width: 100%;
  min-height: 520px;
}

.tab-view {
  .p-tabview {
    padding: 0px;
  }
}

.margin-top-bottom-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.p-toolbar-group-right {
  .p-button {
    margin-left: 10px;
  }
}

.padding-0 {
  padding: 0px;
}

.p-tooltip {
  max-width: 17rem;
}
