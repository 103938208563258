.column-width-percent-5 {
  width: 5%;
}

.column-width-percent-6 {
  width: 6%;
}

.column-width-percent-7 {
  width: 7%;
}

.column-width-percent-8 {
  width: 8%;
}

.column-width-percent-10 {
  width: 10%;
}

.column-width-percent-12 {
  width: 12%;
}

.column-width-percent-14 {
  width: 14%;
}

.column-width-percent-15 {
  width: 15%;
}

.column-width-percent-16 {
  width: 16%;
}

.column-width-percent-18 {
  width: 18%;
}

.column-width-percent-20 {
  width: 20%;
}

.column-width-percent-25 {
  width: 25%;
}

.column-width-percent-30 {
  width: 25%;
}

.column-width-percent-50 {
  width: 50%;
}

.column-align-center {
  text-align: center;
  overflow: hidden;
}

.column-align-left {
  text-align: left;
  overflow: hidden;
}

.column-align-left-pre {
  text-align: left;
  overflow: hidden;
  white-space: pre-wrap;
}

.column-align-right {
  text-align: right;
  overflow: hidden;
}
