.layout-in-progress {
  text-align: center;

  .text {
    margin-top: 100px;
    font-size: 20px;
  }
  img {
    margin-top: 100px;
  }
}
