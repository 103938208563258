.avatar-dropdown {
  width: 12em;
  img {
    display: inline-block;
    margin: 5px 0 0 5px;
  }
  span {
    float: right;
    margin: 0.5em 0.25em 0 0;
  }
}
