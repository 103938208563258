.layout-message {
  .margin-bottom-15 {
    margin-bottom: 15px;
  }

  .display-none {
    display: none;
  }

  .dialog-width {
    width: 1000px;
  }

  .p-datepicker {
    position: absolute !important;
  }

  a:hover {
    text-decoration: none;
  }

  .scrollable-content {
    box-sizing: border-box;
    height: 100%;
    outline: none;
    overflow-y: auto;
    padding: 12px 15px;
    tab-size: 4;
    -moz-tab-size: 4;
    text-align: left;
  }

  .p-datatable {
    .p-datatable-wrapper {
      .p-datatable-thead {
        th {
          text-align: center;
        }
        tr {
          text-align: center;
        }
        .p-sortable-column {
          background-color: white;
          text-align: center;
        }
        .p-filter-column {
          background-color: white;
          .p-inputtext {
            width: 100%;
          }
        }
      }
    }
  }

  .p-toolbar-group-left {
    button.p-button.p-component {
      background-color: #3e97d3;
      border-radius: 10px;
      border: none;
    }
  }

  div.p-toolbar.p-component {
    background-color: white;
  }

  .p-toolbar-group-right {
    button.p-button.p-component {
      background-color: #ef4723;
      border-radius: 10px;
      border: none;
    }
  }

  .column-width-150 {
    width: 150px;
  }

  .column-width-170 {
    width: 170px;
  }

  .column-width-200 {
    width: 200px;
  }

  .column-width-250 {
    width: 250px;
  }

  .column-width-230 {
    width: 230px;
  }

  .text-left {
    text-align: left;
  }

  .p-datatable .p-datatable-tbody tr .td-left {
    text-align: left;
  }

  .p-datatable .p-datatable-tbody tr .td-right {
    text-align: right;
  }

  .text-center {
    text-align: center;
  }
}
