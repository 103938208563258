.layout-topbar {
  height: auto;
  top: 0px;
  left: 0px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 5px 0px;
  padding: 1em 1em;
  color: #ffffff;
  z-index: 999;
  right: 0;
  @include clearfix();
  @include linear-gradient-left($topbarLeftBgColor, $topbarRightBgColor);
  @include transition(left $transitionDuration);

  .application-name {
    font-size: 4em;
    text-transform: uppercase;
  }

  .layout-menu-button {
    cursor: pointer;

    text-decoration: none;
    color: $topbarItemColor;
    float: left;
    @include transition(color $transitionDuration);

    span {
      font-size: 2em;
    }

    &:hover {
      color: $topbarItemHoverColor;
    }
  }
}

.layout-topbar-menu {
  text-align: center;

  .layout-topbar-menuitem {
    position: relative;
    color: $topbarItemColor;
    display: inline-block;
    text-decoration: none;
    @include transition(color $transitionDuration);
    overflow: visible;
    text-align: center;
    user-select: none;

    a {
      color: white;

      &:hover {
        color: $topbarItemHoverColor;
      }
    }

    span {
      &.menuitem-icon {
        line-height: 0;
        font-size: 2em;
        display: block;
      }

      &.menuitem-label {
        padding: 0px;
        font-size: 13px;
        display: block;
      }
    }
  }
}

.margin-right-20 {
  margin-right: 20px;
}
