.layout-activity {
  .margin-bottom-15 {
    margin-bottom: 15px;
  }

  .track-snapshot {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .profile-picture {
    img {
      border-radius: 50%;
    }
  }

  .dialog-width {
    width: 1000px;
  }

  img {
    align-content: center;
    margin: 0;
    padding: 0;
  }

  .text-left {
    text-align: left;
  }

  .font-size-small {
    font-size: 0.8em;
  }

  .font-size-medium {
    font-size: medium;
  }

  .color-red {
    color: red;
  }

  .text-center {
    text-align: center;
  }

  .text-bold {
    font-weight: bold;
  }

  .panel-activity {
   
    .p-panel-header {
      text-align: center;
      color: white;
      background-color: $LOCHINVAR;
      .p-panel-title{
        margin: auto;
      }
    }

    .p-toolbar-separator {
      margin-right: 0.25em;
      margin-left: 0.25em;
    }
  }
}

.view-comments {
  color: orange;
  margin: 5px;
}

.view-comments:hover {
  color: blue;
  cursor: pointer;
}

.horizontal-line {
  color: white;
}
.gmap {
  width: 100%;
  min-height: 400px;
}

.tab-view {
  .p-tabview {
    padding: 0px;
  }
}

.margin-top-bottom-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.p-toolbar-group-right {
  .p-button {
    margin-left: 10px;
  }
}

.padding-0 {
  padding: 0px;
}
