.layout-common {
  padding-right: 20px;
  .label-input-text {
    text-align: left;
  }

  .input-number {
    text-align: right;
  }

  .two-line-text-border {
    border-left: 1px solid $grey;
    padding-left: 10px;
  }

  .two-line-text-first {
    text-align: left;
  }

  .two-line-text-second {
    text-align: left;
    font-weight: bold;
  }

  .full-width {
    width: 100%;
  }
}
