.layout-faq {
  div.p-panel-titlebar,
  div.p-toolbar.p-component {
    background-color: white;
  }

  .p-sortable-column,
  tr.p-datatable-row,
  th {
    background-color: white !important;
    color: black !important;
  }

  span.p-sortable-column-icon.pi.pi-fw.pi-sort-up {
    color: #333333;
  }

  span.p-sortable-column-icon.pi.pi-fw.pi-sort-down {
    color: #333333;
  }

  tr.p-datatable-row.p-highlight {
    background-color: #3e97d3 !important;
    color: white !important;
  }

  .p-toolbar-group-left {
    button.p-button.p-component {
      background-color: #3e97d3;
      border-radius: 10px;
      border: none;
    }
  }

  .p-datatable {
    .p-datatable-wrapper {
      .p-datatable-thead {
        .p-sortable-column {
          text-align: center;
        }
        .p-filter-column {
          .p-inputtext {
            width: 100%;
          }
        }
      }
    }
  }

  a:hover {
    text-decoration: none;
  }

  .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #848484;
  }

  .p-toolbar-group-right {
    button.p-button.p-component {
      background-color: #ef4723;
      border-radius: 10px;
      border: none;
    }
  }
}
