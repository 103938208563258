.layout-rang-list {
  .container-item {
    border-bottom: 1px solid #c8c8c8;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .container-last-item {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  div.p-tabview-panels {
    padding: 0;
  }

  .column-width-120 {
    width: 120px;
  }

  .p-sortable-column,
  tr.p-datatable-row,
  th {
    background-color: white !important;
    color: black !important;
  }
  span.p-sortable-column-icon.pi.pi-fw.pi-sort-up {
    color: #333333;
  }
  span.p-sortable-column-icon.pi.pi-fw.pi-sort-down {
    color: #333333;
  }
  tr.p-datatable-row:hover {
    background-color: $CURIOUS_BLUE !important;
    color: white !important;
  }

  .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #848484;
  }

  a:hover {
    text-decoration: none;
  }

  .p-datatable {
    .p-datatable-wrapper {
      .p-datatable-thead {
        .p-sortable-column {
          text-align: center;
        }
        .p-filter-column {
          .p-inputtext {
            width: 100%;
          }
        }
      }
    }
  }
}
